@import 'mixins';


::selection{
    background-color: $main-orange;
    color: white;
}

::-webkit-scrollbar{
    // display: none;
}
*{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
}


html{
    scroll-behavior: smooth;
}

*{
    margin: 0px;
    padding: 0px;
    font-family: $robot;
}

.order-button{
    cursor: pointer;
}

.container{
    max-width: 1200px;
    width: 100%;
    // background-color: yellow ;
}

h2{
    text-align: center;
    width: calc(100% - 20px);
    padding: 0px 10px 0px 10px;
    // background-color: pink;
    font-size: 36px;
    font-weight: 400;
    @include flexRow(center);
    align-items: center;

    // &::before{
    //     margin-right: 10px;
    //     border-radius: 5px;
    //     display: block;
    //     content: "";
    //     width: 2px;
    //     height: 30px;
    //     background-color: $main-orange;
    // }
    // &::after{
    //     margin-left: 10px;
    //     border-radius: 5px;
    //     display: block;
    //     content: "";
    //     width: 2px;
    //     height: 30px;
    //     background-color: $main-orange;
    // }

}

.site-header{
    width: 100%;
    height: 70px;
    // background-color: red;
    background-color: black;
    @include flexRow(center);
    // box-shadow: 0px 5px 30px 0px black;
    z-index: 2;

    &__container{
        // background-color: yellow;
        @include flexRow(space-between);
        align-items: center;
    }

    &__logo-block{
        // background-color: pink;
        // height: 100%;
        @include flexColumn(center);
        text-decoration: none;
        font-size: 36px;
        color: white;
        font-weight: 400;
        letter-spacing: 2px;
    }

    &__mobile-button{
        width: 40px;
        height: 40px;
        // background-color: blue;
        @include flexRow(center);
        align-items: center;
        position: relative;
        *{
            transition: 0.3s;
        }
        display: none
    }

    &__mobile-button_active{
        path{
            position: absolute;
            &:nth-child(1){
                transform: rotate(45deg) translate(5px, -5px);
            }
            &:nth-child(2){
                opacity: 0;
            }
            &:nth-child(3){
                transform: rotate(-45deg) translate(-12px, -2px);
                // transform: translate(0px, -28px);

            }
        }
    }

    &__nav{
        // background-color: plum;
        @include flexRow(space-between);
    }

    &__menu{
        // background-color: orange;
        @include flexRow(space-between);
        list-style: none;
    }

    &__menu-item{
        // background-color: aqua;
        margin: 0px 10px 0px 10px;
        @include flexColumn(center);
        &:first-child{
            margin-left: 0px;
        }
        &:last-child{
            margin-right: 0px;
        }
    }

    &__menu-link{
        text-decoration: none;
        color: white;
        font-size: 20px;
        font-weight: 400;
    }

    &__order-button{
        width: 146px;
        height: 36px;
        // background-color: aqua;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: white;
        font-weight: 600;
        border-radius: 5px;
        border: 2px solid white;
        margin-left: 50px;
        transition: 0.3s;
        &:hover{
            background-color: white;
            color: black;
        }
    }
}

.main-section{
    // margin-top: 100px;
    width: 100%;
    height: 500px;
    background-color: red;
    @include flexRow(center);
    background-image: url(./../src/img/main-section.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    box-shadow: 0px 5px 0px 0px $main-orange;

    &__cover{
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 40%;
        position: absolute;
    }

    &__container{
        // background-color: yellow;
        @include flexColumn(flex-start);
        align-items: center;
        z-index: 1;
    }

    &__header{
        color: white;
        font-size: 46px;
        margin-top: 150px;
        font-weight: 400;
    }

    &__button{
        width: 200px;
        height: 50px;
        // background-color: red;
        margin-top: 50px;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        color: white;
        text-decoration: none;
        border-radius: 5px;
        border: 2px solid white;
        transition: 0.3s;
        font-size: 20px;
        background-color: black;
        &:hover{
            background-color: white;
            color: black;
        }
    }
}

.brands-section{
    width: 100%;
    // height: 500px;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);
    background-color: darken(white, 5%);

    &__container{
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__brands-container{
        @include flexRow(center);
        flex-wrap: wrap;
        width: 100%;
        // height: 400px;
        // background-color: pink;
        margin-top: 50px;
    }

    &__brand{
        width: 150px;
        min-height: 50px;
        padding: 5px 0px 5px 0px;
        background-color: white;
        // background-color: plum;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: black;
        border-radius: 0px 10px 0px 10px;
        margin: 10px;
        transition: 0.3s;
        &:hover{
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.514);
        }
    }
}

.problems-section{
    width: 100%;
    // height: 500px;
    // background-color: red;
    background-color: darken(white, 5%);
    @include flexRow(center);
    padding: 50px 0px 50px 0px;

    &__container{
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__toggles-container{
        width: 100%;
        // height: 30px;
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__toggle{
        cursor: pointer;
        margin: 10px;
        padding: 5px;
        border-radius: 5px;
        transition: 0.3s;
        &:hover{
            background-color: lighten($main-orange, 10%);
            color: white;
        }
    }

    &__toggle_active{
        background-color: $main-orange;
        color: white;
        pointer-events: none;
        &:hover{
            background-color: $main-orange;
        }
    }

    &__problems-container{
        width: 100%;
        // height: 400px;
        // background-color: pink;
        margin-top: 50px;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__problem{
        background-color: white;
        border-radius: 5px;
        overflow: hidden;
        width: 300px;
        height: 300px;
        // width: calc(360px - 30px);
        // height: calc(260px - 30px);
        // padding: 15px;
        // background-color: plum;
        margin: 10px;
        position: relative;
        @include flexColumn(flex-start);
    }

    &__img-block{
        width: 100%;
        height: 200px;
        // background-color: orange;
    }

    &__img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        // display: none;
    }

    &__problem-name{
        // background-color: orange;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        margin-top: 15px;
        font-size: 20px;
        font-weight: 400;
    }

    &__button{
        width: 150px;
        height: 40px;
        // background-color: red;
        margin-top: 10px;
        margin-left: 10px;
        border-radius: 5px;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: white;
        background-color: $main-orange;
        transition: 0.3s;
        &:hover{
            background-color: darken($main-orange, 10%);
        }
    }
}

.services-section{
    width: 100%;
    // height: 500px;
    // background-color: red;
    background-color: darken(white, 5%);
    @include flexRow(center);
    padding: 50px 0px 50px 0px;

    &__container{
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__services-container{
        width: 100%;
        // height: 400px;
        // background-color: pink;
        margin-top: 50px;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__service{
        background-color: white;
        border-radius: 5px;
        overflow: hidden;
        width: 300px;
        height: 300px;
        // width: calc(360px - 30px);
        // height: calc(260px - 30px);
        // padding: 15px;
        // background-color: plum;
        margin: 10px;
        position: relative;
        @include flexColumn(flex-start);
    }

    &__img-block{
        width: 100%;
        height: 200px;
        // background-color: orange;
    }

    &__img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        // display: none;
    }

    &__service-name{
        // background-color: orange;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        margin-top: 15px;
        font-size: 20px;
        font-weight: 400;
    }

    &__button{
        width: 150px;
        height: 40px;
        // background-color: red;
        margin-top: 10px;
        margin-left: 10px;
        border-radius: 5px;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: white;
        background-color: $main-orange;
        transition: 0.3s;
        &:hover{
            background-color: darken($main-orange, 10%);
        }
    }
}

.pluses-section{
    width: 100%;
    // height: 300px;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);
    // margin-top: 50px;

    &__container{
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        color: $main-dark;
    }

    &__pluses-container{
        width: 100%;
        // height: 1000px;
        // min-height: 200px;
        // background-color: pink;
        margin-top: 50px;
        @include flexRow(center);
        flex-wrap: wrap;
    }
    
    &__plus{
        width: calc(300px - 30px);
        height: calc(100% - 30px);
        max-height: 200px;
        height: 200px;
        padding: 15px;
        // background-color: plum;
        @include flexColumn(flex-start);
        // margin: 5px 7.5px 5px 7.5px;
        &:first-child{
            margin-left: 0px;
        }
        &:last-child{
            margin-right: 0px;
        }
    }

    &__plus-content{
        color: $main-orange;
        font-size: 46px;
        font-weight: 600;
        text-align: center;
        // background-color: orange;
    }

    &__plus-text{
        // background-color: orange;
        margin-top: 10px;
        font-size: 30px;
        color: $main-dark;
        text-align: center;
    }
}

.cats-section{
    width: 100%;
    // min-height: 500px;
    // background-color: red;
    // background-color: darken(white, 10%);
    background-color: black;
    @include flexRow(center);
    // margin-top: 100px;
    padding: 50px 0px 50px 0px;
    box-shadow: 0px -5px 0px 0px $main-orange, 0px 5px 0px 0px $main-orange;

    &__container{
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        color: white;
    }

    &__cats-container{
        // background-color: pink;
        width: 100%;
        // min-height: 500px;
        margin-top: 50px;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__cat{
        margin: 10px 10px 10px 10px;
        max-width: 360px;
        width: 100%;
        height: 200px;
        // background-color: plum;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        transition: 0.3s;
        &:hover{
            .cats-section__cat-img{
                transform: scale(1.1);
            }

            .cats-section__button{
                margin-top: 100px;
            }
        }
        
    }

    &__cat-img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        transition: 0.3s;
    }

    &__cat-cover{
        width: 100%;
        height: 100%;
        background-color: black;
        position: absolute;
        opacity: 30%;
    }

    &__cat-content-block{
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        // background-color: orange;
        padding: 15px;
        position: absolute;
        @include flexColumn(center);
        align-items: center;
    }

    &__cat-header{
        width: 100%;
        text-align: center;
        color: white;
        font-weight: 400;
        font-size: 20px;
        // margin-top: 30px;
    }

    &__button{
        width: 150px;
        height: 40px;
        // background-color: red;
        margin-top: 20px;
        position: absolute;
        margin-top: 300px;
        transition: 0.3s;
        text-decoration: none;
        color: white;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.473);
        border: 2px solid white;
        transition: 0.3s;
        &:hover{
            background-color: black;
        }
    }
}

.questions-section{
    width: 100%;
    // height: 500px;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__questions-container{
        width: 100%;
        // height: 400px;
        // background-color: pink;
        margin-top: 50px;
        @include flexColumn(flex-start);
    }

    &__question-block{
        width: 100%;
        min-height: 40px;
        // background-color: plum;
        box-shadow: 0px 3px 0px 0px black;
        @include flexColumn(flex-start);
        &:not(:first-child){
            margin-top: 30px;
        }
    }

    &__header-block{
        cursor: pointer;
        &:not(.questions-section__header-block_active){
            // background-color: red;
            &:hover{
                color: $main-orange;
                svg{
                    path{
                        stroke: $main-orange;
                    }
                }
            }
        }
    }

    &__header-block{
        width: calc(100% - 10px);
        padding: 0px 5px 0px 5px;
        min-height: 40px;
        // background-color: orange;
        @include flexRow(space-between);
        align-items: center;
        transition: 0.3s;
    }
    
    &__header-block_active{
        color: $main-orange;
    }

    &__question-header{
        // background-color: aqua;
        max-width: 80%;
        font-weight: 400;
        font-size: 24px;
    }

    &__question-header_active{
        // color: $main-orange;
    }

    &__question-button{
        transition: 0.3s;
        cursor: pointer;
        path{
            transition: 0.3s;
            stroke: black;
        }       
    }

    &__question-button_active{
        transform: rotate(45deg);
        path{
            stroke: $main-orange;
        }

    }

    &__answer{
        width: calc(100% - 10px);
        padding: 5px 5px 10px 5px;
        // background-color: orange;
        margin-top: 5px;
        display: none;
    }
}


.reviews-section{
    width: 100%;
    // height: 500px;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__reviews-container{
        width: 100%;
        // height: 400px;
        margin-top: 50px;
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__review{
        margin: 10px 10px 10px 10px;
        width: calc(360px - 30px);
        min-height: calc(200px - 30px);
        padding: 15px;
        // background-color: plum;
        background-color: lighten(black, 90%);
        border-radius: 5px;
        @include flexColumn(flex-start);
    }

    &__review-text{
        max-height: 150px;
        // background-color: orange;
        font-size: 20px;
        overflow: hidden;
        // overflow-y: scroll;
    }

    &__review-cat{
        margin-top: 15px;
        // background-color: orange;
        color: darken(white, 70%);
    }

    &__review-author{
        margin-top: 5px;
        font-weight: 500;
        // background-color: orange;
    }

}

.area-section{
    width: 100%;
    // height: 200px;
    padding: 50px 0px;
    // background-color: red;
    background-color: rgb(236, 236, 236);
    // margin-top: 100px;
    @include flexRow(center);

    &__container{
        width: 1180px;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__areas-toggle-block{
        width: 100%;
        height: 50px;
        // background-color: pink;
        margin-top: 50px;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__main-area{
        width: 250px;
        height: 100%;
        // background-color: plum;
        text-align: center;
        @include flexColumn(center);
        color: $main-dark;
        cursor: pointer;
        transition: 0.3s;
        margin: 0px 10px 0px 10px;
        border-radius: 30px;
        &:hover{
            background-color: lighten($main-dark, 10%);
            color: white;
        }
        &:active{
            background-color: $main-dark;
        }
    }

    &__main-area_active{
        background-color: $main-dark;
        color: white;
        border-radius: 30px;
        &:hover{
            background-color: $main-dark;
        }
    }

    &__areas-block{
        width: 100%;
        // height: 500px;
        // background-color: yellow;
        margin-top: 20px;
        @include flexRow(center);
        flex-wrap: wrap;
        position: relative;
        display: none;
    }

    &__close-button{
        position: absolute;
        width: 30px;
        height: 30px;
        // background-color: red;
        @include flexColumn(center);
        align-items: center;
        left: calc(100% - 30px);
        margin-top: -20px;
        // left: 100%;
        cursor: pointer;
        
        svg{
            transition: 0.3s;
        }

        &:hover{
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__area-block{
        width: 150px;
        height: 70px;
        text-align: center;
        text-decoration: none;
        // background-color: fuchsia;
        background-color: white;
        margin: 15px;
        color: $main-dark;
        @include flexColumn(center);
        border-radius: 5px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.315);
        
        &:hover{
            transform: scale(1.1);
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.411);
        }
    }
}

.site-footer{
    padding: 50px 0px;
    width: 100%;
    // height: 300px;
    background-color: black;
    box-shadow: 0px -5px 0px 0px $main-orange;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: red;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__cats-block{
        margin: 20px 20px 0px 20px;
        height: 300px;
        width: 300px;
        // background-color: pink;
        @include flexRow(space-between);
        flex-wrap: wrap;
        overflow-y: scroll;

        &::-webkit-scrollbar{
            width: 3px;
        }

        &::-webkit-scrollbar-thumb{
            background-color: $main-orange;
        }
    }
    
    &__cat{
        text-decoration: none;
        @include flexColumn(center);
        color: darken(white, 15%);
        width: 130px;
        // background-color: plum;
        margin-top: 5px;
        min-height: 30px;
        &:hover{
            color: white;
        }
    }
    
    &__contacts-block{
        margin: 20px 20px 0px 20px;
        max-width: 700px;
        width: 100%;
        min-height: 300px;
        // background-color: pink;
        @include flexColumn(flex-start);
        
    }

    &__contacts-container{
        width: 100%;
        min-height: 50px;
        // background-color: plum;
        @include flexRow(center);
        flex-wrap: wrap;
        align-items: center;
    }

    &__contact{
        // width: 100%;
        font-size: 17px;
        text-decoration: none;
        color: white;
        // height: 30px;
        // background-color: orange;
        margin-top: 5px;
        margin: 15px;
        transition: 0.1s;
        &:hover{
            text-decoration: underline;
        }
    }

    &__map-block{
        width: 100%;
        height: 250px;
        background-color: $main-orange;
        // margin-top: 30px;
    }
}



.error-message{
    width: 100%;
    height: 90vh;
    @include flexColumn(center);
    align-items: center;
    a{
        color: $main-orange;
    }
}

.modal-window{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.37);
    position: absolute;
    z-index: 99999;
    @include flexRow(center);
    align-items: center;
    display: none;

    &__form{
        padding: 30px;
        // width: 500px;
        // height: 400px;
        background-color: white;
        border-radius: 5px;
        @include flexColumn(flex-start);
        align-items: center;
        position: relative;
    }

    &__close-button{
        position: absolute;
        // background-color: red;
        width: 20px;
        height: 20px;
        @include flexColumn(center);
        align-items: center;
        left: calc(100% - 25px);
        bottom: calc(100% - 25px);
        cursor: pointer;
        svg{
            transition: 0.3s;
        }
        &:hover{
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__header{
        font-size: 20px;
        width: 200px;
    }

    &__user-input{
        margin-top: 20px;
        width: 200px;
        height: 30px;
        border: 1px solid $main-orange;
        border-radius: 5px;
        padding: 0px 0px 0px 5px;
        outline: none;
        font-size: 18px;
        font-weight: 300;
    }

    &__button{
        width: 210px;
        height: 40px;
        background-color: $main-dark;
        margin-top: 15px;
        color: white;
        border: none;
        outline: none;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;

        &:hover{
            background-color: $main-orange;
        }
    }

    &__policy-text{
        width: 210px;
        margin-top: 10px;
        font-size: 12px;
        text-align: center;
        color: lightgrey;
    }
}

.up-button{
    width: 60px;
    height: 60px;
    border-radius: 100px 0px 0px 100px;
    background-color: white;
    box-shadow: 0px 0px 0px 2px $main-orange;
    // background-color: red;
    @include flexColumn(center);
    align-items: flex-start;
    // position: fixed;
    position: fixed;
    top: calc(95% - 65px);
    left: calc(100% - 60px);
    z-index: 100;
    display: none;

    .modal-button__svg-container{
        width: 65px;
        height: 65px;
        border-radius: 65px;
        // background-color: yellow;
        @include flexColumn(center);
        align-items: center;
    }
    svg{
        // background-color: white;
        // padding: 13px;
        // border-radius: 30px;
        width: 40px;
        height: 40px;
        path{
            fill: $main-orange;
        }
    }

    &:hover{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            transform: scale(1.1);
        }
    }

    &:active{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            transform: scale(1.1);
        }
    }
}

@keyframes modalCallAnim {
    0%{transform: rotate(0deg);}
    50%{transform: rotate(10deg);}
    100%{transform: rotate(0deg);}
}

.call-button{
    box-shadow: 0px 0px 0px 5px white;
    width: 60px;
    height: 60px;
    border-radius: 0px 100px 100px 0px;
    background-color: white;
    box-shadow: 0px 0px 0px 2px $main-orange;
    @include flexColumn(center);
    align-items: flex-end;
    // position: fixed;
    position: fixed;
    top: calc(95% - 65px);
    right: calc(100% - 60px);
    z-index: 100;
    display: none;
    
    .modal-button__svg-container{
        width: 65px;
        height: 65px;
        border-radius: 65px;
        // background-color: white;
        @include flexColumn(center);
        align-items: center;
    }
    
    svg{
        // display: none;
        animation: modalCallAnim 1s infinite;
        // background-color: white;
        // padding: 13px;
        // border-radius: 30px;
        fill: $main-orange;
        width: 40px;
        height: 40px;
        path{
            fill: $main-orange;
        }
    }

    &:hover{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            animation-play-state: paused;
            transform: scale(1.1);
        }
    }

    &:active{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            transform: scale(1.1);
        }
    }
}


@media (max-width: 1200px){
    .site-header{
        // background-color: red;
        &__container{
            // background-color: yellow;
            justify-content: space-around;
            align-items: center;
            position: relative;
        }

        &__mobile-button{
            @include flexColumn(center);
            align-items: center;
        }

        &__nav{
            z-index: 2;
            position: absolute;
            background-color: black;
            margin-top: 261px;
            width: 100%;
            @include flexRow(center);
            // display: none;
        }
        
        &__menu{
            padding: 0px 0px 10px 0px;
            @include flexColumn(flex-start);
            display: none;
            align-items: center;
            // background-color: orange;
        }

        &__menu-item{
            text-align: center;
            // background-color: pink;
            height: unset;
            margin-top: 10px;
        }

        &__order-button{
            margin-top: 10px;
            box-shadow: none;
            margin-left: 0px;
        }
    }

    .main-section{
        background-size: cover;

        &__header{
            width: calc(100% - 10px);
            text-align: center;
            padding: 0px 5px 0px 5px;
        }
    }

    .area-section{
        // display: none;
        &__areas-block{
            margin-top: 70px;
        }

        &__close-buton{
            left: calc(100% - 30px);
        }
    }
}



